import { Component } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { NavigationService } from './services/navigation/navigation.service';
import { LocationFlowService } from './services/location/location-flow.service';
import { UserService } from './services/user/user.service';
import { PlatformLocation } from '@angular/common';
import { SocketService } from './services/socket/socket.service';
import { FcmService } from './services/fcm/fcm.service';
import { StorageService } from './services/storage/storage.service';
// import { Editor } from 'ngx-editor';
import { register } from 'swiper/element/bundle';
register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    tab1Active: boolean = true;
    tab2Active: boolean = false;
    tab3Active: boolean = false;
    tab4Active: boolean = false;

    constructor(
        public navigationService: NavigationService,
        public locationFlowService: LocationFlowService,
        public userService: UserService,
        public platform: Platform,
        public fcm: FcmService,
        public pltLocation: PlatformLocation,
        public socketService: SocketService,
        public storageService: StorageService

        // public editor: Editor
    ) {
        // this.userService.getCurrentLoggedInUserData().subscribe(async (res: any) => {
        //     console.log("🚀 ~ file: app.component.ts:27 ~ AppComponent ~ this.userService.getCurrentLoggedInUserData ~ res:", res)
        //     this.userService.currentUser = res.data
        // })

        this.platform.ready().then((platformRes) => {
            console.log("platfrom ready method called", platformRes)

            if (platformRes != 'dom') {
                //this.setupFCM();
                fcm.initializePushNotifService()
                //CHECK FOR APP VERSION: ONLY VERSION FOR IOS OR ANDROID DEVICES
                // this.authService.getAppVersion().subscribe(res => {
                //     //HANDLE NAVIGATION BASED ON THE REPONSE
                //     // console.log("app version response", res);

                // }, err => {
                //     // console.log("error in version call", err);
                // })
                // 	//HU-
                this.platform.pause.subscribe(() => {
                    this.socketService.disconnect()
                })

                this.platform.resume.subscribe(() => {
                    this.socketService.initSocket()
                })
            }




        })
    }

    ngOnInit() {
        this.checkForPlatform();
        // this.locationFlowService.openlocationpage();
        if (JSON.parse(this.storageService.getStorage('currentUser'))) this.getLoggedInUserData();
    }

    checkPlatform() {
        if (this.platform.platforms().includes('ios') && this.platform.platforms().includes('mobileweb')) return ("ioswebbrowser")
        else if (this.platform.platforms().includes('android') && this.platform.platforms().includes('mobileweb')) return ("androidwebbrowser")
        else if (this.platform.platforms().includes('ios')) return ("ios")
        else if (this.platform.platforms().includes('android')) return ("android")
        else return ("web")
    }

    setPlatformCssVarVal(platform) {
        // Dynamically set margin-top based on platform
        const marginTop = platform === 'ios' ? '3rem' : '0rem';
        document.documentElement.style.setProperty('--platform-margin-top', marginTop);
    }

    async checkForPlatform() {
        let platform = this.checkPlatform();
        console.log("platformplatformplatformplatform", platform)

        sessionStorage.setItem('platform', platform)

        this.setPlatformCssVarVal(platform);
    }

    getLoggedInUserData() {
        this.userService.getCurrentLoggedInUserData().subscribe((res: any) => {
            console.log("🚀 ~ file: app.component.ts:27 ~ AppComponent ~ this.userService.getCurrentLoggedInUserData ~ res:", res)
            if (res.success != false) {
                this.userService.currentUser = res.data
                this.userService.userBehaviourSubject.next(res.data);
                this.userService.isUserCallDone = true;
            }
        })
    }


    // ngOnDestroy(): void {
    //     this.editor.destroy();
    // }

    // tabActive(activeTab) {
    //     switch (activeTab) {

    //         case 'tab1':
    //             this.tab1Active = true;
    //             this.tab2Active = false;
    //             this.tab3Active = false;
    //             this.tab4Active = false;
    //             this.navigationService.toHome();
    //             break;

    //         case 'tab2':
    //             this.tab1Active = false;
    //             this.tab2Active = true;
    //             this.tab3Active = false;
    //             this.tab4Active = false;
    //             this.navigationService.toPrograms();
    //             break;

    //         case 'tab3':
    //             this.tab1Active = false;
    //             this.tab2Active = false;
    //             this.tab3Active = true;
    //             this.tab4Active = false;
    //             this.navigationService.toMessages();
    //             break;

    //         case 'tab4':
    //             this.tab1Active = false;
    //             this.tab2Active = false;
    //             this.tab3Active = false;
    //             this.tab4Active = true;
    //             this.navigationService.toAccount();
    //             break;
    //     }
    // }
}
