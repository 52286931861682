import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/internal/operators/tap';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProgramService {
    baseServerUrl: any = environment.API_URL
    onlineplatformList = [
        "Zoom",
        "Microsoft Teams",
        "Google Meet",
        "Skype",
        "Cisco Webex",
        "Slack ",
        "GoToMeeting",
        "BlueJeans",
        "Adobe Connect",
        "Zoho Meeting",
        "Join.me",
        "Discord",
        "BigBlueButton",
        "Jitsi Meet",
        "RingCentral Video",
        "Others"
    ];
    msg: string;

    constructor(
        private http: HttpClient,
        public storageService: StorageService
    ) { }

    getAllProgramsForHomeWall() {
        // return this.http.get(this.baseServerUrl + '/program/get-home-programs').pipe(tap((data) => {
        return this.http.get(this.baseServerUrl + `/home-page/get-home-page-programs/${JSON.parse(this.storageService.getStorage('currentUser'))._id}?pageNumber=1&platform=` + sessionStorage.getItem('platform')).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    getAllProgramsForHomeWallGeneral() {
        // return this.http.get(this.baseServerUrl + '/program/get-home-programs').pipe(tap((data) => {
        const headers = new HttpHeaders({
            'skipInterceptor': 'true' // Custom header to skip interceptor
        });
        console.log(sessionStorage.getItem('platform'))

        return this.http.get(this.baseServerUrl + '/program/get-all-programs-for-welcome-page?platform=' + sessionStorage.getItem('platform'), { headers }).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    getAllUpcomingProgramsForHomeWall() {
        return this.http.get(this.baseServerUrl + `/home-page/get-user-enrolled-programs/${JSON.parse(this.storageService.getStorage('currentUser'))._id}`).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }


    getProgram(id) {
        console.log("user status", this.storageService.getStorage('currentUserId'))
        if (!this.storageService.getStorage('currentUserId')) {
            return this.http.get(this.baseServerUrl + `/program/get-single-program/public/${id}`).pipe(tap((data) => {
                console.log("🚀 ~ file: program.service.ts:28 ~ ProgramService ~ returnthis.http.get ~ data:", data)
                //setup any user data here
            }), catchError((err) => {
                throw (err)
            }))
        } else {
            return this.http.get(this.baseServerUrl + `/program/get-single-program/${id}`).pipe(tap((data) => {
                console.log("🚀 ~ file: program.service.ts:28 ~ ProgramService ~ returnthis.http.get ~ data:", data)
                //setup any user data here
            }), catchError((err) => {
                throw (err)
            }))
        }

    }

    getProgramGeneral(id) {
        const headers = new HttpHeaders({
            'skipInterceptor': 'true' // Custom header to skip interceptor
        });
        return this.http.get(this.baseServerUrl + `/program/get-single-program/${id}`, { headers }).pipe(tap((data) => {
            console.log("🚀 ~ file: program.service.ts:28 ~ ProgramService ~ returnthis.http.get ~ data:", data)
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }
    getProgramEnrollmentList(programId) {
        return this.http.get(this.baseServerUrl + `/program/get-program-enrollees/${programId}`).pipe(tap((data) => {
            console.log("🚀 ~ file: program.service.ts:28 ~ ProgramService ~ returnthis.http.get ~ data:", data)
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    getMyPrograms(myProgramsIdArray) {
        return this.http.post(this.baseServerUrl + '/program/get-my-program?platform=' + sessionStorage.getItem('platform'), JSON.stringify(myProgramsIdArray)).pipe(tap((data) => {
            //setup any user data here
            console.log("🚀 ~ file: program.service.ts:35 ~ ProgramService ~ returnthis.http.post ~ data:", data)
        }), catchError((err) => {
            throw (err)
        }))
    }

    getJoinServiceAlertMsg() {
        return this.http.get(this.baseServerUrl + `/program/get-join-service-alert-msg`).pipe(tap((data) => {
            //setup any user data here
            console.log("🚀 ~ file: program.service.ts:35 ~ ProgramService ~ returnthis.http.post ~ data:", data)
        }), catchError((err) => {
            throw (err)
        }))
    }

    createProgram(programObject) {
        // console.log("Program Obj in service: ",programObject)
        return this.http.post(this.baseServerUrl + '/program/create-program', JSON.stringify(programObject)).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    updateProgram(updateProgramObject, id) {
        return this.http.patch(this.baseServerUrl + '/program/update-program-details/' + id, JSON.stringify(updateProgramObject)).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }
    canJoinProgram(programid) {
        return this.http.get(this.baseServerUrl + '/program/check-if-program-enrollment-eligible?id=' + programid).pipe(tap((data) => {
            //setup any user data here

        }), catchError((err) => {
            throw (err)
        }))
    }

    joinProgram(joinProgramObj) {
        return this.http.patch(this.baseServerUrl + '/program/join-program/', JSON.stringify(joinProgramObj)).pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    getMySyllabus() {
        return this.http.get(this.baseServerUrl + `/syllabus/getSyllabus`).pipe(tap((data) => {
            console.log("🚀 ~ file: program.service.ts:67 ~ getSyllabus ~ returnthis.http.get ~ data:", data)
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    getAllProgramsDrafts() {
        return this.http.get(this.baseServerUrl + '/program/get-my-program-drafts').pipe(tap((data) => {
            //setup any user data here
        }), catchError((err) => {
            throw (err)
        }))
    }

    refreshProgramDetailsSubject: BehaviorSubject<any> = new BehaviorSubject({})
    refreshProgramDetails$: Observable<any> = this.refreshProgramDetailsSubject.asObservable()

    callRefreshPrograms(data) {
        this.refreshProgramDetailsSubject.next(data);
    }

}