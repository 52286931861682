import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { SearchService } from 'src/app/services/search/search.service';
import { LocationFlowService } from 'src/app/services/location/location-flow.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TabsPage } from 'src/app/tabs/tabs.page';
import { UserService } from 'src/app/services/user/user.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { AlertController, ModalController } from '@ionic/angular';
import { WelcomePage } from 'src/app/pages/welcome/welcome.page';
import { Router } from '@angular/router';

@Component({
    selector: 'app-web-header-bar',
    templateUrl: './web-header-bar.component.html',
    styleUrls: ['./web-header-bar.component.scss'],
})
// export class WebHeaderBarComponent implements OnInit {
export class WebHeaderBarComponent {

    selectedlocation: any;

    @Output() searchStringEvent = new EventEmitter<string>();
    @Input('onSearchPage') onSearchPage;
    @Input('isActiveTab') isActiveTab;
    @Input('isActiveTabHome') isActiveTabHome;
    @Input('isActiveTabPrograms') isActiveTabPrograms;
    @Input('isActiveTabMessages') isActiveTabMessages;
    @Input('isActiveTabAccount') isActiveTabAccount;
    @Input('isActiveTabNotifications') isActiveTabNotifications;
    @Input('isActiveTabUserProfile') isActiveTabUserProfile;

    constructor(
        public navigationService: NavigationService,
        public locationFlowService: LocationFlowService,
        public searchService: SearchService,
        public storageService: StorageService,
        public tabsPage: TabsPage,
        public userService: UserService,
        public notificationsService: NotificationsService,
        public alertController: AlertController,
        public modalCtrl: ModalController,
         public router: Router,
    ) {
        this.selectedlocation = this.storageService.getLocationStorage("selectedCity") + ", " + this.storageService.getLocationStorage("selectedCountry");
    }

    // ngOnInit() {
    //     console.log("🚀 ~ file: web-header-bar.component.ts:20 ~ WebHeaderBarComponent ~ ngOnInit ~ this.onSearchPage:", this.onSearchPage)
    // }

    // ngAfterViewInit() {
    //     console.log("🚀 ~ file: web-header-bar.component.ts:41 ~ WebHeaderBarComponent ~ ngOnInit ~ this.isActiveTabHome:", this.isActiveTabHome)
    //     console.log("🚀 ~ file: web-header-bar.component.ts:41 ~ WebHeaderBarComponent ~ ngOnInit ~ this.isActiveTabPrograms:", this.isActiveTabPrograms)
    //     console.log("🚀 ~ file: web-header-bar.component.ts:41 ~ WebHeaderBarComponent ~ ngOnInit ~ this.isActiveTabMessages:", this.isActiveTabMessages)
    //     console.log("🚀 ~ file: web-header-bar.component.ts:41 ~ WebHeaderBarComponent ~ ngOnInit ~ this.isActiveTabAccount:", this.isActiveTabAccount)
    //     console.log("🚀 ~ file: web-header-bar.component.ts:41 ~ WebHeaderBarComponent ~ ngOnInit ~ this.isActiveTabNotifications:", this.isActiveTabNotifications)
    // }

    sendSearchString(e) {
        console.log("🚀 ~ file: web-header-bar.component.ts:22 ~ WebHeaderBarComponent ~ sendSearchString ~ e:", e.target.value)
        this.searchService.performSearch(e.target.value)
        // this.searchStringEvent.emit(e.target.value);
    }

    locationvalueEmpty() {
        if ((this.locationFlowService.displaylocation).includes('false')) {
            return true;
        }
        return false;
    }

    checkIfUserIsProvider() {
        if (this.storageService.getStorage('currentUser')) {
            // console.log("logged in user --------------- ")
            console.log("🚀 ~ file: web-header-bar.component.ts:71 ~ WebHeaderBarComponent ~ checkIfUserIsProvider ~ this.userService.currentUser.is_service_provider:", this.userService.currentUser.is_service_provider)
            if (this.userService.currentUser.is_service_provider == 'true' || this.userService.currentUser.is_service_provider == true) this.navigationService.toCreateProgram()
            if (this.userService.currentUser.is_service_provider == 'pending') this.presentAlertProfileUnderReview()
            if (this.userService.currentUser.is_service_provider == 'false' || this.userService.currentUser.is_service_provider == false) this.navigationService.toCreateProvider()
     
        }
        else{
            this.presentLogin();
        }
   }

    async presentAlertProfileUnderReview() {
        const alertForProfileUnderReview = this.alertController.create({
            message: "Looks like you've already submitted your profile, which is great! It's currently under review and we should have an update for you within 48 hours. Thanks for your patience, and we'll get back to you soon!",
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                    handler: () => { }
                }
            ]
        });
        (await alertForProfileUnderReview).present();
    }
     async presentLogin() {
            try {
                const CommunityGuidelinesModal = await this.modalCtrl.create({
                    component: WelcomePage,
                    cssClass: 'ModalViewLogin',
                    backdropDismiss: true,
                });
                CommunityGuidelinesModal.present();
                localStorage.setItem("activeURLLogin","programedetails");
                const { data, role } = await CommunityGuidelinesModal.onWillDismiss();
                console.log("🚀 ~ file: program-preview.component.ts:171 ~ ProgramPreviewComponent ~ presentCommunityGuidelines ~ data:", data)
                if (data  == 'loginSucess') {
                    // this.USER_LOGGEDIN = true;
                    this.router.navigate(['/tabs/home']);
                }
    
            } catch (error) {
    
            }
        }
}
