import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({
  name: 'displayDate'
})
export class DisplayDatePipe implements PipeTransform {

  transform(value): unknown {
    let datetext =  moment(value).format("MMM DD, YYYY");
    return datetext;
  }

}
