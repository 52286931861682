import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safeHtml/safe-html.pipe';
import { SafePdfPipe } from './safePdf/safe-pdf.pipe';
import { DisplayDatePipe } from './display-date/display-date.pipe';


@NgModule({
    declarations: [SafeHtmlPipe, SafePdfPipe, DisplayDatePipe],
    imports: [
        CommonModule
    ],
    exports: [SafeHtmlPipe, SafePdfPipe , DisplayDatePipe]
})
export class PipesModule { }
