import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserService } from 'src/app/services/user/user.service';
import { MyConfig } from 'src/assets/config/app-config';
import { CategoriesPage } from '../categories/categories.page';
import { RegistrationPage } from '../registration/registration.page';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ForgotPasswordPage } from '../forgot-password/forgot-password.page';
import { CommonFunctionsService } from 'src/app/services/common/common-functions.service';
import { SocketService } from 'src/app/services/socket/socket.service';
import { ProgramService } from 'src/app/services/program/program.service';
import { IonContent } from '@ionic/angular';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.page.html',
    styleUrls: ['./welcome.page.scss'],
    providers: []
})
export class WelcomePage implements OnInit {
    @ViewChild(IonContent) content: IonContent;
    myconfig: any = MyConfig;
    loginForm: FormGroup;
    isPhoneValid: boolean;
    isPhoneEmpty: boolean = true;
    isEmailValid: boolean;
    isEmailEmpty: boolean = true;
    isPasswordValid: boolean;
    isPasswordEmpty: boolean = true;
    showPassword: boolean = false;
    isRememberMeBoxChecked: any;
    submitAttempt: boolean = false;
    countryCode: string = '+1';
    loginerror = "";

    showloginBox : boolean = false;
    constructor(
        public commonFunctions: CommonFunctionsService,
        public formBuilder: FormBuilder,
        public navigationService: NavigationService,
        public modalCtrl: ModalController,
        public userService: UserService,
        public storageService: StorageService,
        public socketService: SocketService,
        public programService: ProgramService,
    ) { }   

    ngOnInit() {
        try {
            this.initForm();
            this.getProgramsForHomeWall()
        }
        catch (error) { }
    }

    initForm() {
        try {
            this.loginForm = this.formBuilder.group({
                email: ['', Validators.compose([Validators.pattern(MyConfig.regex.emailRegex), Validators.required])],
                password: ['', Validators.compose([Validators.pattern(MyConfig.regex.passwordRegex), Validators.required])],
                rememberMe: [false]
                // rememberMe: [false, Validators.compose([Validators.requiredTrue])]
            });
            this.loginerror = "";
        }
        catch (error) { }
    }

    checkValidPhone(e) {
        try {
            let phoneRegex = new RegExp(MyConfig.regex.phoneRegex);
            (e.target.value == '') ? this.isPhoneEmpty = true : this.isPhoneEmpty = false;
            phoneRegex.test(e.target.value) == true ? this.isPhoneValid = true : this.isPhoneValid = false;
            console.log("this.isPhoneEmpty ->", this.isPhoneEmpty, "this.isPhoneValid ->", this.isPhoneValid);
        }
        catch (error) { }
    }

    checkValidEmail(e) {
        try {
            let text = e.target.value.toLowerCase()
            let emailRegex = new RegExp(MyConfig.regex.emailRegex);
            (text == '') ? this.isEmailEmpty = true : this.isEmailEmpty = false;
            emailRegex.test(text) == true ? this.isEmailValid = true : this.isEmailValid = false;
            console.log("this.isEmailEmpty ->", this.isEmailEmpty, "this.isEmailValid ->", this.isEmailValid);
        }
        catch (error) { }
    }

    checkValidPassword(e) {
        try {
            let passwordRegex = new RegExp(MyConfig.regex.passwordRegex);
            (e.target.value == '') ? this.isPasswordEmpty = true : this.isPasswordEmpty = false;
            passwordRegex.test(e.target.value) == true ? this.isPasswordValid = true : this.isPasswordValid = false;
        }
        catch (error) { }
    }

    checkAgeBoxChecked(e) {
        try {
            if (e.target.checked) {
                this.loginForm.patchValue({ rememberMe: true })
                this.isRememberMeBoxChecked = true;
            }
            else {
                this.isRememberMeBoxChecked = false;
            }
            console.log("this.isRememberMeBoxChecked ->", this.isRememberMeBoxChecked);
        }
        catch (error) { }
    }

    toggleViewPassword() {
        try {
            this.showPassword = !this.showPassword;
            console.log("🚀 ~ file: login.page.ts ~ line 80 ~ LoginPage ~ toggleViewPassword ~ this.showPassword", this.showPassword)
        } catch (error) { }
    }

    async login() {
        this.submitAttempt = true;
        this.loginerror = "";
        if (
            this.isEmailValid == true &&
            this.isPasswordValid == true
        ) {
            // this.navigationService.toHome();
            await this.commonFunctions.showLoading("Please wait...")
            let loginObject = {
                email: this.loginForm.value.email,
                password: this.loginForm.value.password
            }
            this.userService.userLogin(loginObject).subscribe(async (res: any) => {
                console.log("🚀 ~ file: welcome.page.ts:118 ~ WelcomePage ~ this.userService.userLogin ~ res:", res)
                console.log("login response", res);
                await this.commonFunctions.hideLoading()
                if (res.hasOwnProperty('success')) {
                    if (res.success == false) {
                        // alert(res.message)
                        await this.commonFunctions.showErrorReporAlert(res.message);
                        await this.commonFunctions.reportAlertMsg.onWillDismiss().then((data) => {
                            if (res.data.isSignupComplete == false) {
                                this.openRegistrationFormModal('verify', res.data.uid);
                            }
                        });
                    }
                    else {
                        if (res.hasOwnProperty('data')) {
                            if (!res.data.hasOwnProperty('token') ||
                                res.data.token == null && res.data.token == "" ||
                                !res.data.hasOwnProperty('expiresIn') || res.data.expiresIn == null ||
                                res.data.expiresIn == "") {
                                // alert("Something went wrong. Cannot login user at this time. Please try again later")
                                await this.commonFunctions.showErrorReporAlert("Something went wrong. Cannot login user at this time. Please try again later");
                                await this.commonFunctions.reportAlertMsg.onWillDismiss().then((data) => {
                                    return
                                });

                            }
                            this.modalCtrl.dismiss("loginSucess");
                            await this.commonFunctions.presentToast(res.message)
                            if(localStorage.getItem("activeURLLogin")==="programedetails"){
                                this.modalCtrl.dismiss();
                                localStorage.removeItem("activeURLLogin")
                                this.programService.callRefreshPrograms("")
                            }else{
                                this.navigationService.toHome(res.data.user)

                            }


                            this.userService.userBehaviourSubject.next(res.data.user);
                            // const setTokenInStorageResponse = this.storageService.setStorage('token', res.data.token)
                            // const setExpInStorage = this.storageService.setStorage('tokenExpiration', res.data.expiresIn)
                            // const setUserObjInStorage = this.storageService.setStorage('currentUser', JSON.stringify(res.data.user))
                            // const setUserIdInStorage = this.storageService.setStorage('currentUserId', JSON.stringify(res.data.user._id))
                            // this.userService.currentUser = res.data.user;

                            // //init socket setup here
                            // this.socketService.initSocket()



                        }


                        // set user token to storage here
                        // check if categories was selected. If not, pass a param to open categories modal and take user to home page
                    }
                }
                else {
                    await this.commonFunctions.hideLoading();
                    // alert("Something went wrong. Cannot login user at this time. Please try again later")
                    await this.commonFunctions.showErrorReporAlert("Something went wrong. Cannot login user at this time. Please try again later");
                    await this.commonFunctions.reportAlertMsg.onWillDismiss().then((data) => {
                    });
                }

            }, async error => {
                await this.commonFunctions.hideLoading();
                this.loginerror = error.error.message;
            })
        }
        else console.log("Login with valid data");

    }



    async openRegistrationFormModal(action, uid) {
        try {

            const registrationFormModal = await this.modalCtrl.create({
                cssClass: 'registration-form-modal',
                component: RegistrationPage,
                backdropDismiss: false,
                componentProps: {
                    action: action,
                    uid: uid,
                    email: this.loginForm.value.email,
                }
            });
            registrationFormModal.present();
        } catch (error) {

        }
    }

    async openForgotPasswordModal() {
        try {

            const forgotPageModal = await this.modalCtrl.create({
                cssClass: 'forgot-page-modal',
                component: ForgotPasswordPage,
                backdropDismiss: false,
                componentProps: {}
            });
            forgotPageModal.present();
        } catch (error) {

        }
    }



    test(e) {
        setTimeout(() => {
            console.log("🚀 ~ file: welcome.page.ts:143 ~ WelcomePage ~ test ~ e:", e)
        }, 500);
    }


    homeWallProgramList: any = []
    getProgramsForHomeWall() {
        
        this.programService.getAllProgramsForHomeWallGeneral().subscribe((res: any) => {
            console.log("🚀 ~ file: home.page.ts:47 ~ HomePage ~ this.programService.getAllProgramsForHomeWall ~ res:", res)
                 this.homeWallProgramList = res;
          })
    }

    scrollToTop() {
        this.content.scrollToTop(500);
      }

}
